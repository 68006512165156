<template>
  <div class="header">
    <!-- :style="`${collapse?'padding-left: 70px;':'padding-left: 140px;'}`" -->
    <!-- 折叠按钮 -->
    <div class="collapse-btn" @click="collapseChage">
      <!-- <i v-if="collapse" class="el-icon-s-unfold"></i>
   <i v-else class="el-icon-s-fold"></i> -->
      <!-- <img src="../../assets/img/logo.png" alt="" class="logoImg" /> -->
      <img :src="PlatformLogo" alt="" class="logoImg" />
      <div class="logo">{{ PlatformName }}</div>
    </div>
    <div class="header-right">
      <div class="header-user-con">
        <!-- 全屏显示 -->
        <div class="btn-fullscreen" @click="handleFullScreen">
          <el-tooltip
            effect="dark"
            :content="fullscreen ? `取消全屏` : `全屏`"
            placement="bottom"
          >
            <i class="el-icon-rank"></i>
          </el-tooltip>
        </div>
        <!-- 消息中心 -->
        <!-- <div class="btn-bell">
          <el-tooltip effect="dark" :content="message?`有${message}条未读消息`:`消息中心`" placement="bottom">
            <router-link to="/tabs">
              <i class="el-icon-bell"></i>
            </router-link>
          </el-tooltip>
          <span class="btn-bell-badge" v-if="message"></span>
        </div> -->
        <!-- 用户头像 -->

        <!-- 用户名下拉菜单 -->
        <el-dropdown class="user-name" @command="handleCommand">
          <div class="flex-start">
            <div class="user-avator">
              <img src="../../assets/img/img.jpg" />
            </div>
            <span class="el-dropdown-link">
              {{ username }}
              <i class="el-icon-caret-bottom"></i>
            </span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item divided command="password">
              <i class="el-icon-edit-outline font-l"></i>
              <span>修改密码</span>
            </el-dropdown-item>
            <el-dropdown-item divided command="logout">
              <i class="el-icon-position font-l"></i>

              退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- 重置密码 -->
    <reset-password :visible.sync="visibleReset"></reset-password>
  </div>
</template>
<script>
// import {
//   logout,
// } from "@/api";

import ResetPassword from "@/components/common/ResetPassword";
export default {
  name: "HeaderVue",

  components: { ResetPassword },

  data() {
    return {
      fullscreen: false,
      loading: false,
      name: "linxin",
      message: 2,
      // 重置密码数据
      visibleReset: false,
    };
  },
  computed: {
    username() {
      let username = sessionStorage.getItem("username");
      username = username.replace(/\"/g, "");
      return username ? username : this.name;
    },
    collapse() {
      return this.$store.state.collapse;
    },
    PlatformName() {
      if (this.$store.state.user.brandName) {
        return this.$store.state.user.brandName + "智能客控平台";
      }
      return this.$store.state.PlatformName;
    },
    PlatformLogo() {
      if (this.$store.state.user.logo) {
        return this.$store.state.user.logo;
      }
      return this.$store.state.PlatformLogo;
    },
  },
  methods: {
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command == "logout") {
        this.$store.commit("SET_TOKEN");
        sessionStorage.clear();
        location.reload();
        this.$destroy();
      } else {
        this.visibleReset = true;
      }
    },
    // 侧边栏折叠
    collapseChage() {
      let val = !this.collapse;
      this.$store.commit("SET_COLLAPSE", val);
    },
    // 全屏事件
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
  },
  mounted() {
    if (document.body.clientWidth < 1500) {
      this.collapseChage();
    }
  },
};
</script>
<style scoped>
.my-input {
  max-width: 215px;
}
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  font-size: 22px;
  /* background: linear-gradient(to right,#044fff,#63b5f8); */
  background: linear-gradient(to right, #242f42, #56667d);
  transition: all 0.5s;
  color: #fff;
}
.logoImg {
  height: 60px;
}
.collapse-btn {
  /* float: left; */
  padding: 0 21px;
  cursor: pointer;
  line-height: 70px;
  display: flex;
  align-items: center;
}
.header .logo {
  padding-left: 10px;
  /* float: left; */
  width: 250px;
  line-height: 70px;
}
.header-right {
  padding-right: 20px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.header-user-con {
  display: flex;
  height: 70px;
  align-items: center;
}
.btn-fullscreen {
  transform: rotate(45deg);
  /* margin-right: 5px; */
  font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}
.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}
.btn-bell .el-icon-bell {
  color: #fff;
}
.user-name {
  margin-left: 10px;
}
.flex-start {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-avator {
  margin: 0 10px;
}
.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.el-dropdown-link {
  color: #fff;
}
.el-dropdown-menu__item {
  text-align: center;
}
</style>
