<template>
  <div class="wrapper">
    <v-head></v-head>
    <v-sidebar :items="items"> </v-sidebar>
    <div class="content-box" :class="{ 'content-collapse': collapse }">
      <v-tags></v-tags>
      <div class="content" :class="{ scroll_y: route == '/kfjk' }">
        <el-backtop
          target=".content"
          :right="route == '/kfjk' ? 160 : 50"
          :visibility-height="300"
        >
          <div class="upIcon">UP</div>
        </el-backtop>
        <transition name="slide-fade" mode="out-in">
          <keep-alive :include="tagsList">
            <router-view></router-view>
          </keep-alive>
        </transition>
      </div>
    </div>
    <!-- <audio id="audio" controls hidden="true">
          <source src="../../../static/audio/sos.mp3" type="audio/mpeg">
        </audio> -->
  </div>
</template>

<script>
import vHead from "./Header.vue";
import vSidebar from "./Sidebar.vue";
import vTags from "./Tags.vue";
import bus from "./bus";
import { getUserJurisdiction, getRoomStatusList } from "@/api";

export default {
  data() {
    return {
      tagsList: [],
      items: null,
    };
  },
  components: {
    vHead,
    vSidebar,
    vTags,
  },

  created() {
    // console.log(this.$route);
    // 只有在标签页列表里的页面才使用keep-alive，即关闭标签之后就不保存到内存中了。
    bus.$on("tags", (msg) => {
      let arr = [];
      for (let i = 0, len = msg.length; i < len; i++) {
        msg[i].name && arr.push(msg[i].name);
      }
      this.tagsList = arr;
    });
    this.getMenuList();
  },
  methods: {
    getMenuList() {
      var data = JSON.parse(sessionStorage.getItem("user"));
      var user = data.username;
      // 获取数据
      getUserJurisdiction(user).then((res) => {
        // let _tempData = res.data[0].children;
        this.items = res.data.data[0].children;
        this.deleteBtn(this.items);
        // console.info(JSON.stringify(_temp));
        // _temp = this.removeBtn(_temp);
        // _temp = this.removeNullChild(_temp);
        this.$store.commit("GET_MENU_LIST", this.items);
      });
    },
    deleteBtn(list) {
      let _obj = null; //临时对象
      let _index = 0; //下标

      //直接无限循环
      while (true) {
        _obj = list[_index]; //获取当前下标的对象

        //如果对象的type为1，直接删除
        if (_obj.type == "1") {
          list.splice(_index, 1);

          //删除后，如果下标已经越界，证明上面所删除的已经是最后一个
          if (_index == list.length) {
            break;
          } else {
            //如果还没有越界，继续处理当前下标的数据，因为删除当前下标数据后，后面的数会向前推进一位
            continue;
          }
        } else {
          //如果对象的type为0
          if (_obj.children) {
            //如果对象有子数据
            this.deleteBtn(_obj.children); //直接递归处理

            //如果递归完成之后，被处理对象的子数据长度为0时，把这个子数据集删除
            if (_obj.children.length == 0) {
              delete list[_index].children;
            }
          }
        }

        //如果循环到list的下标，跳出循环
        if (_index == list.length - 1) {
          break;
        } else {
          //递增1
          _index++;
        }
      }
    },
  },

  computed: {
    collapse() {
      return this.$store.state.collapse;
    },
    getMenu() {
      return this.$store.state.menu;
    },
    route() {
      return this.$route.path;
    },
  },
  watch: {
    getMenu: {
      handler(val) {
        this.items = val;
      },
    },
  },
};
</script>
<style scoped>
/* .content{
  overflow-x: hidden;
} */
.upIcon {
  height: 100%;
  width: 100%;
  background-color: #f2f5f6;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  text-align: center;
  line-height: 40px;
  color: #1989fa;
}
/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
/* .slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter {
  transform: translateX(-100%);
  opacity: 0;
}
.slide-fade-leave-to{
  transform: translateX(100%);
  opacity: 0;
} */
</style>
