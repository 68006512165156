var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar" },
    [
      _c(
        "el-menu",
        {
          staticClass: "sidebar-el-menu",
          attrs: {
            "default-active": _vm.$route.path,
            collapse: _vm.collapse,
            "background-color": "rgba(0,0,0,0)",
            "text-color": "#bfcbd9",
            "active-text-color": "#20a0ff",
            "unique-opened": "",
          },
          on: { select: _vm.handleSelect },
        },
        [
          _vm._l(_vm.items, function (item, index) {
            return [
              item.children
                ? [
                    _c(
                      "el-submenu",
                      { key: index, attrs: { index: index + "" } },
                      [
                        _c("template", { slot: "title" }, [
                          _c("i", { class: item.icon }),
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ]),
                        _vm._l(item.children, function (subItem) {
                          return [
                            subItem.children
                              ? _c(
                                  "el-submenu",
                                  {
                                    key: subItem.path,
                                    attrs: { index: subItem.path },
                                  },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _vm._v(_vm._s(subItem.name)),
                                    ]),
                                    _vm._l(
                                      subItem.children,
                                      function (threeItem, i) {
                                        return _c(
                                          "el-menu-item",
                                          {
                                            key: i,
                                            attrs: { index: threeItem.path },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(threeItem.name) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _c(
                                  "el-menu-item",
                                  {
                                    key: subItem.path,
                                    attrs: { index: subItem.path },
                                  },
                                  [_vm._v(_vm._s(subItem.name))]
                                ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                : [
                    _c(
                      "el-menu-item",
                      {
                        key: item.path,
                        staticStyle: { padding: "0 8px" },
                        attrs: { index: item.path },
                      },
                      [
                        _c("i", { class: item.icon }),
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v(_vm._s(item.name))]
                        ),
                      ]
                    ),
                  ],
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }