var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showTags
    ? _c("div", { staticClass: "tags" }, [
        _c(
          "ul",
          _vm._l(_vm.tagsList, function (item, index) {
            return _c(
              "li",
              {
                key: index,
                staticClass: "tags-li",
                class: { active: _vm.isActive(item.path) },
              },
              [
                _c(
                  "router-link",
                  { staticClass: "tags-li-title", attrs: { to: item.path } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(item.title) +
                        "\n            "
                    ),
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "tags-li-icon",
                    on: {
                      click: function ($event) {
                        return _vm.closeTags(index)
                      },
                    },
                  },
                  [_c("i", { staticClass: "el-icon-close" })]
                ),
              ],
              1
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "tags-close-box" },
          [
            _c(
              "el-dropdown",
              { on: { command: _vm.handleTags } },
              [
                _c("el-button", { attrs: { size: "mini", type: "primary" } }, [
                  _vm._v("\n                标签选项"),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                ]),
                _c(
                  "el-dropdown-menu",
                  {
                    attrs: { slot: "dropdown", size: "small" },
                    slot: "dropdown",
                  },
                  [
                    _c("el-dropdown-item", { attrs: { command: "other" } }, [
                      _vm._v("关闭其他"),
                    ]),
                    _c("el-dropdown-item", { attrs: { command: "all" } }, [
                      _vm._v("关闭所有"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }