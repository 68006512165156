<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-active="$route.path"
      :collapse="collapse"
      background-color="rgba(0,0,0,0)"
      text-color="#bfcbd9"
      active-text-color="#20a0ff"
      unique-opened
      @select="handleSelect"
    >
      <!-- router -->
      <template v-for="(item, index) in items">
        <template v-if="item.children">
          <el-submenu :index='index+""' :key="index">
            <template slot="title">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.name }}</span>
            </template>
            <template v-for="subItem in item.children">
              <el-submenu v-if="subItem.children" :index="subItem.path" :key="subItem.path">
                <template slot="title">{{ subItem.name }}</template>
                <el-menu-item 
                  v-for="(threeItem,i) in subItem.children"
                  :key="i"
                  :index="threeItem.path">
                  {{ threeItem.name }}
                </el-menu-item>               
              </el-submenu>
              <el-menu-item v-else :index="subItem.path" :key="subItem.path">{{ subItem.name }}</el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.path" :key="item.path" style="padding: 0 8px">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import bus from '../common/bus';
import kanlist from "@/router/kanlist.js"
export default {
  data () {
    return {
    }
  },
  props: {
    items: {
      type: Array,
    }
  },
  computed: {
    openUrl(){
      let arr=[]
      kanlist.forEach(v=>{
        arr.push(v.path)
      })
      return arr
    },
    onRoutes () {
      return this.$route.path.replace('/', '');
    },
     collapse(){
        return this.$store.state.collapse
      }
  },
  methods: {
    handleSelect(key, keyPath){
      let path=this.$route.path
      if(key==path){
        return
      }else if(this.openUrl.includes(key)){
        let routeUrl = this.$router.resolve({
            path: key,
          });
          window.open(routeUrl.href, '_blank');
      }else{
        this.$router.push(key)
      }
    }
  },
}
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 140px;
 
}

.sidebar-el-menu{
 background: linear-gradient( #242f42, #56667d);
 /* border-right: none !important; */

}
.sidebar > ul {
  height: 100%;
}
.sidebar i {
  width: 24px;
  height: 18px;
  margin-right: 10px;
  font-weight: bold
}
</style>
