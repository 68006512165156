var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header" },
    [
      _c(
        "div",
        { staticClass: "collapse-btn", on: { click: _vm.collapseChage } },
        [
          _c("img", {
            staticClass: "logoImg",
            attrs: { src: _vm.PlatformLogo, alt: "" },
          }),
          _c("div", { staticClass: "logo" }, [
            _vm._v(_vm._s(_vm.PlatformName)),
          ]),
        ]
      ),
      _c("div", { staticClass: "header-right" }, [
        _c(
          "div",
          { staticClass: "header-user-con" },
          [
            _c(
              "div",
              {
                staticClass: "btn-fullscreen",
                on: { click: _vm.handleFullScreen },
              },
              [
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      effect: "dark",
                      content: _vm.fullscreen ? "取消全屏" : "全屏",
                      placement: "bottom",
                    },
                  },
                  [_c("i", { staticClass: "el-icon-rank" })]
                ),
              ],
              1
            ),
            _c(
              "el-dropdown",
              { staticClass: "user-name", on: { command: _vm.handleCommand } },
              [
                _c("div", { staticClass: "flex-start" }, [
                  _c("div", { staticClass: "user-avator" }, [
                    _c("img", {
                      attrs: { src: require("../../assets/img/img.jpg") },
                    }),
                  ]),
                  _c("span", { staticClass: "el-dropdown-link" }, [
                    _vm._v(
                      "\n            " + _vm._s(_vm.username) + "\n            "
                    ),
                    _c("i", { staticClass: "el-icon-caret-bottom" }),
                  ]),
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      { attrs: { divided: "", command: "password" } },
                      [
                        _c("i", { staticClass: "el-icon-edit-outline font-l" }),
                        _c("span", [_vm._v("修改密码")]),
                      ]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { divided: "", command: "logout" } },
                      [
                        _c("i", { staticClass: "el-icon-position font-l" }),
                        _vm._v("\n\n            退出登录"),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("reset-password", {
        attrs: { visible: _vm.visibleReset },
        on: {
          "update:visible": function ($event) {
            _vm.visibleReset = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }