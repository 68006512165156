var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("v-head"),
      _c("v-sidebar", { attrs: { items: _vm.items } }),
      _c(
        "div",
        {
          staticClass: "content-box",
          class: { "content-collapse": _vm.collapse },
        },
        [
          _c("v-tags"),
          _c(
            "div",
            {
              staticClass: "content",
              class: { scroll_y: _vm.route == "/kfjk" },
            },
            [
              _c(
                "el-backtop",
                {
                  attrs: {
                    target: ".content",
                    right: _vm.route == "/kfjk" ? 160 : 50,
                    "visibility-height": 300,
                  },
                },
                [_c("div", { staticClass: "upIcon" }, [_vm._v("UP")])]
              ),
              _c(
                "transition",
                { attrs: { name: "slide-fade", mode: "out-in" } },
                [
                  _c(
                    "keep-alive",
                    { attrs: { include: _vm.tagsList } },
                    [_c("router-view")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }